const REFERRER_KEY = '__t_referrer';
const TEST_STORAGE_KEY = '_________TEST_________';

const referrerStats = {};

function storageMixin(rs) {
  if (!rs) return;
  rs.getStats = () => JSON.parse(window.sessionStorage.getItem(REFERRER_KEY)) || [];
  rs.setStats = (referrers) => {
    window.sessionStorage.setItem(REFERRER_KEY, JSON.stringify(referrers));
  };
}

function polyfillMixin(rs) {
  try {
    // 是否为无痕模式或被禁用本地储存
    window.sessionStorage.setItem(TEST_STORAGE_KEY, '1');
    window.sessionStorage.removeItem(TEST_STORAGE_KEY);
  } catch (e) {
    console.warn('Storage或被禁用');
    rs.getStats = () => JSON.parse(window.name || JSON.stringify('')) || [];
    rs.setStats = (referrers) => {
      // 小程序等其他环境不处理
      if (typeof window === 'undefined') {
        return;
      }

      window.name = JSON.stringify(referrers);
    };
  }
}

storageMixin(referrerStats);
polyfillMixin(referrerStats);

export default referrerStats;
